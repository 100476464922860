import { ReactNode } from "react";
import { ApplicationErrorBoundaryPage } from "core/pages/ApplicationErrorBoundaryPage";
import { usePermissionContext } from "core/hooks/usePermissionContext";
import { useMenuItems } from "core/hooks/useMenuItems";
import { useToken } from "core/hooks/useToken";
import { useVariableDebounced } from "core/hooks/useVariableDebounced";
import { ApplicationLoadingDisplay } from "../ApplicationLoadingDisplay";
import { ErrorComponent } from "../ErrorComponent";

interface GlobalLoaderProps {
  children: ReactNode;
}
export function MainLoader({ children }: GlobalLoaderProps) {
  const {
    isLoading: isPermissionContextLoading,
    isError: permissionContextError,
  } = usePermissionContext();

  const { isLoading: isMenuItemsLoading, isError: menuItemsError } =
    useMenuItems();

  const isError = permissionContextError || menuItemsError;

  // using debounce to prevent multiple visible loading state changes
  const isLoading = useVariableDebounced(
    isPermissionContextLoading || isMenuItemsLoading,
    500
  );

  if (isError) {
    return (
      <ApplicationErrorBoundaryPage>
        <ErrorComponent apiError={isError} />
      </ApplicationErrorBoundaryPage>
    );
  }
  if (isLoading) {
    return <ApplicationLoadingDisplay />;
  }

  return <>{children}</>;
}

function TokenLoader({ children }: { children: ReactNode }) {
  const token = useToken();
  if (!token) {
    return <ApplicationLoadingDisplay />;
  }
  return <>{children}</>;
}

export function GlobalLoader({ children }: { children: ReactNode }) {
  return (
    <TokenLoader>
      <MainLoader>{children}</MainLoader>
    </TokenLoader>
  );
}
