import { useUserLog } from "core/hooks/useUserLog";
import React, { useState, MouseEvent } from "react";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useAuth } from "oidc-react";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { isDevelopment } from "config";
import { exhaustiveCheck } from "core/utils/exhaustiveCheck";
import { useToaster } from "core/hooks/useToaster";
import { useNavigate } from "core/hooks/useNavigate";
import { useRegion } from "core/hooks/useRegion";
import { useToken } from "core/hooks/useToken";
import { useAvailableRegions } from "core/hooks/useAvailableRegions";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import { useUserTokenInfo } from "core/hooks/useUserTokenInfo";
import { copyTranslationsToClipboard } from "i18n.config";
import { useSWRConfig } from "swr";
import { usePermission } from "core/hooks/usePermission";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import { CustomLink } from "./CustomLink";
import { useAvailableLocales } from "../hooks/useAvailableLocales";
import { UserAvatar } from "./UserAvatar";
import { AvailableRegionMenuItem } from "./AvailableRegionMenuItem";

export type MenuOption = "logout" | "region-select" | "user-preferences";
type DevMenuOption =
  | "copy-translations"
  | "test-page"
  | "theme-inspect-page"
  | "localization-inspect-page";

function useUserMenu() {
  const { successToast, errorToast } = useToaster();
  const { addLog } = useUserLog();
  const { push } = useRouter();
  const navigate = useNavigate();
  const { signOutRedirect } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  function handleOpenUserMenu(e: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
  }
  async function handleCloseUserMenu(
    option: MenuOption | DevMenuOption | null
  ) {
    if (!option) {
      setAnchorEl(null);
      return;
    }
    switch (option) {
      case "logout":
        await addLog("Page", "logout");
        await signOutRedirect();
        break;
      case "region-select":
        push("/region-select");
        break;
      case "user-preferences":
        await navigate("/user-preferences");
        break;
      case "copy-translations":
        try {
          copyTranslationsToClipboard();
          successToast("Translations copied to clipboard");
        } catch (e) {
          errorToast(
            "Translations failed to copy to clipboard, are you browsing from a secure origin?"
          );
        }
        break;
      case "test-page":
        await navigate("/test-page");
        break;
      case "localization-inspect-page":
        await navigate("/localization-inspect");
        break;
      case "theme-inspect-page":
        await navigate("/theme-inspect");
        break;
      default:
        exhaustiveCheck(option);
        break;
    }
    setAnchorEl(null);
  }
  return { anchorEl, handleOpenUserMenu, handleCloseUserMenu, setAnchorEl };
}

interface UserMenuProps {
  menuItems?: MenuOption[];
}

function ExtensoLoginMenuItem() {
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:UserMenu" });
  const region = useRegion();
  const { locale: lg } = useRouter();
  const token = useToken();
  const page = "payment-request";
  return (
    <CustomLink
      color="text.primary"
      href={{
        pathname: "/extenso-redirect",
        query: { region, lg, token, page, path: page },
      }}
      target="_blank"
      rawHref
    >
      <MenuItem>{t("option.extensoLogin")}</MenuItem>
    </CustomLink>
  );
}

export function UserMenu({
  menuItems = ["region-select", "logout", "user-preferences"],
}: UserMenuProps) {
  const canUseDevOptions = usePermission("Fixhub.DevOptions");
  const { i18n } = useTranslation();
  const { mutate } = useSWRConfig();
  const availableRegions = useAvailableRegions();
  const currentRegion = useRegion();
  const { anchorEl, setAnchorEl, handleCloseUserMenu, handleOpenUserMenu } =
    useUserMenu();
  const { isDesktop } = useBreakpoints();
  const { locale, pathname, push, asPath, query } = useRouter();
  const { availableLocales } = useAvailableLocales();
  const { nickname, given_name: givenName, name } = useUserTokenInfo() || {};
  const displayName = nickname ?? name ?? givenName ?? "";
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:UserMenu" });
  const { t: tCore } = useTranslation("Fixhub");

  function selectLanguage(nextLocale: string) {
    return () => {
      setAnchorEl(null);
      push({ pathname, query }, asPath, { locale: nextLocale });
    };
  }

  return (
    <>
      <Button
        variant="text"
        sx={{ color: "primary.contrastText" }}
        onClick={handleOpenUserMenu}
        color="secondary"
        data-testid="user-menu-button"
      >
        <UserAvatar />
        {isDesktop && (
          <Typography
            px={1}
            textTransform="none"
            display="flex"
            alignItems="center"
          >
            {displayName}
            <ArrowDropDownIcon />
          </Typography>
        )}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(anchorEl)}
        onClose={async () => {
          await handleCloseUserMenu(null);
        }}
      >
        {!isDesktop && (
          <Divider textAlign="center">
            <Typography color="text.secondary" variant="overline">
              {displayName}
            </Typography>
          </Divider>
        )}
        {canUseDevOptions && [
          <Divider key="devOptionsDivider" textAlign="center">
            <Typography color="text.secondary" variant="overline">
              {t("devOptions")}
            </Typography>
          </Divider>,
          <Box key="toggle-translation-keys" sx={{ mx: 1 }}>
            <FormControlLabel
              labelPlacement="end"
              data-testid="toggle-translation-keys"
              label={t("translationKeyToggleLabel")}
              control={
                <Switch
                  checked={i18n.language === "cimode"}
                  onChange={(_, checked) => {
                    if (checked) {
                      i18n.changeLanguage("cimode");
                    } else {
                      i18n.changeLanguage(locale);
                    }
                  }}
                />
              }
            />
          </Box>,
          isDevelopment ? (
            <MenuItem
              key="copy-translations"
              onClick={async () => {
                await handleCloseUserMenu("copy-translations");
              }}
            >
              {t(`option.copy-translations`)}
            </MenuItem>
          ) : null,
          <MenuItem
            key="localization-inspect-page"
            onClick={async () => {
              await handleCloseUserMenu("localization-inspect-page");
            }}
          >
            {t(`option.localization-inspect-page`)}
          </MenuItem>,
          <MenuItem
            key="test-page"
            onClick={async () => {
              await handleCloseUserMenu("test-page");
            }}
          >
            {t(`option.test-page`)}
          </MenuItem>,
          <MenuItem
            key="theme-inspect-page"
            onClick={async () => {
              await handleCloseUserMenu("theme-inspect-page");
            }}
          >
            {t(`option.theme-inspect-page`)}
          </MenuItem>,
          <ExtensoLoginMenuItem key="extenso-login" />,
          <Divider key="end-divider" sx={{ my: 2 }} />,
        ]}
        {menuItems.map((option) => (
          <MenuItem
            key={option}
            onClick={async () => {
              await handleCloseUserMenu(option);
            }}
          >
            {t(`option.${option}`)}
          </MenuItem>
        ))}
        <Divider textAlign="center">
          <Typography color="text.secondary" variant="overline">
            {t("language")}
          </Typography>
        </Divider>
        {availableLocales?.map((selectedLocale) => (
          <MenuItem
            selected={locale === selectedLocale}
            key={selectedLocale}
            onClick={selectLanguage(selectedLocale)}
          >
            {tCore(`Next:Core:LanguageCodes.${selectedLocale}`)}
          </MenuItem>
        ))}
        {availableRegions.length > 1 && (
          <Divider textAlign="center">
            <Typography color="text.secondary" variant="overline">
              {t("region")}
            </Typography>
          </Divider>
        )}
        {availableRegions.length > 1 &&
          availableRegions?.map((availableRegion) => (
            <AvailableRegionMenuItem
              selected={currentRegion === availableRegion}
              region={availableRegion}
              pathname={pathname}
              query={query}
              locale={locale}
              onClick={async () => {
                // clear the cache for the current region
                // https://swr.vercel.app/docs/advanced/cache#modify-the-cache-data
                if (currentRegion !== availableRegion) {
                  await mutate(
                    () => true, // which cache keys are updated
                    undefined, // update cache data to `undefined`
                    { revalidate: false } // do not revalidate
                  );
                }
                handleCloseUserMenu(null);
              }}
              label={tCore(`Region:RegionName.${availableRegion}`)}
              key={availableRegion}
            />
          ))}
      </Menu>
    </>
  );
}
